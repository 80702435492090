import React, { useState, useEffect, useMemo } from 'react';
import { useUser, useClerk } from '@clerk/clerk-react';
import { createClient } from '@supabase/supabase-js';
import { format, startOfWeek, endOfWeek, eachDayOfInterval, startOfDay, endOfDay, addDays, subWeeks, addWeeks, isToday, isSameDay, isWithinInterval, formatDistanceToNow } from 'date-fns';
import { Check, Timer, LayoutDashboard, FolderKanban, BarChart3, Users, Settings, ChevronLeft, ChevronRight, Plus, Clock, Activity, ChevronsUpDown } from 'lucide-react';

// UI Components
import { Card, CardContent, CardHeader, CardTitle } from './ui/card';
import { Button } from './ui/button';
import { Input } from './ui/input';
import { Avatar, AvatarImage, AvatarFallback } from './ui/avatar';
import { Tabs, TabsContent, TabsList, TabsTrigger } from './ui/tabs';
import { DateRangePicker } from './ui/date-range-picker';
import { Badge } from './ui/badge';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from './ui/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from './ui/popover';

// Custom Components
import Members from './Members.jsx';
import UserReports from './UserReports.jsx';
import ProjectChart from './ProjectChart';
import Timesheet from './Timesheet';
import ProjectRates from './ProjectRates';
import ProjectSummaryTable from './ProjectSummaryTable';

// Utils
import { cn } from '../lib/utils';

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
);

// Add this function at the top level
const getGradient = (str) => {
  const gradients = [
    'from-indigo-500 via-purple-500 to-pink-500',
    'from-rose-400 via-fuchsia-500 to-indigo-500',
    'from-blue-500 via-teal-500 to-emerald-500',
    'from-yellow-400 via-orange-500 to-pink-500',
    'from-green-400 via-cyan-500 to-blue-500',
    'from-purple-500 via-violet-500 to-indigo-500'
  ];
  
  // Generate a consistent index based on the string
  const index = str?.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0) % gradients.length;
  return gradients[index];
};

// Move SuccessAnimation component outside TimeTracker
const SuccessAnimation = ({ onComplete }) => {
  React.useEffect(() => {
    const timer = setTimeout(onComplete, 1000);
    return () => clearTimeout(timer);
  }, [onComplete]);

  return (
    <div className="fixed top-5 left-1/2 -translate-x-1/2 z-50 px-6 py-3 rounded-lg bg-emerald-900/90 backdrop-blur-sm flex items-center gap-2 animate-in fade-in slide-in-from-top-full duration-200 animate-out fade-out duration-200 delay-800">
      <Check className="h-4 w-4 text-emerald-400" />
      <span className="text-white">Updated successfully</span>
    </div>
  );
};

// Define TimeTracker component
const TimeTracker = () => {
  const { user } = useUser();
  const { signOut } = useClerk();
  const [selectedView, setSelectedView] = useState('logTime');
  const [dateRange, setDateRange] = useState([]);
  const [entries, setEntries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [projectUsers, setProjectUsers] = useState({});
  const [projectRates, setProjectRates] = useState({});
  const [selectedJiraProject, setSelectedJiraProject] = useState(null);
  const [selectedJiraTicket, setSelectedJiraTicket] = useState(null);
  const [jiraProjects, setJiraProjects] = useState([]);
  const [jiraTickets, setJiraTickets] = useState([]);
  const [loadingTickets, setLoadingTickets] = useState(false);
  const [newEntry, setNewEntry] = useState({
    hours: '',
    minutes: '',
    description: ''
  });
  const [showDropdown, setShowDropdown] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [currentWeekStart, setCurrentWeekStart] = useState(() => startOfWeek(new Date(), { weekStartsOn: 1 }));
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [showForm, setShowForm] = useState(false);
  const [userRates, setUserRates] = useState({});
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [openProjectSelect, setOpenProjectSelect] = useState(false);
  const [projectWeeklyGoals, setProjectWeeklyGoals] = useState({});

  // Initialize with current week (Monday-Sunday)
  useEffect(() => {
    const now = new Date();
    const monday = startOfWeek(now, { weekStartsOn: 1 });
    const sunday = endOfWeek(monday, { weekStartsOn: 1 });
    setDateRange([monday, sunday]);
    setCurrentWeekStart(monday);
  }, []);

  // Fetch time entries based on selected view
  useEffect(() => {
    const fetchEntries = async () => {
      try {
        setLoading(true);
        let query = supabase
          .from('time_entries')
          .select('*')
          .order('date', { ascending: false });

        // Only filter by user for the Log Time view
        if (selectedView === 'logTime' && user?.emailAddresses?.[0]?.emailAddress) {
          query = query.eq('user_email', user.emailAddresses[0].emailAddress);
        }

        const { data, error } = await query;
        if (error) throw error;

        setEntries(data || []);
      } catch (error) {
        console.error('Error fetching entries:', error);
        setError('Failed to load time entries');
      } finally {
        setLoading(false);
      }
    };

    fetchEntries();
  }, [selectedView, user?.emailAddresses]);

  // Function to get entries for a specific date
  const getEntriesForDate = (date) => {
    return entries.filter(entry => {
      const entryDate = new Date(entry.date);
      const isSameDate = format(entryDate, 'yyyy-MM-dd') === format(date, 'yyyy-MM-dd');
      
      // Only show current user's entries in Log Time view
      if (selectedView === 'logTime') {
        return isSameDate && entry.user_email === user?.emailAddresses?.[0]?.emailAddress;
      }
      
      return isSameDate;
    });
  };

  // Function to calculate total hours for a date
  const getTotalHoursForDate = (date) => {
    const dayEntries = getEntriesForDate(date);
    const totalHours = dayEntries.reduce((sum, entry) => sum + (entry.duration / (1000 * 60 * 60)), 0);
    return Number(totalHours.toFixed(1)); // Ensure consistent decimal places
  };

  // Week navigation handlers
  const handlePrevWeek = () => {
    const newWeekStart = subWeeks(currentWeekStart, 1);
    setCurrentWeekStart(newWeekStart);
    setDateRange([newWeekStart, endOfWeek(newWeekStart, { weekStartsOn: 1 })]);
  };

  const handleNextWeek = () => {
    const newWeekStart = addWeeks(currentWeekStart, 1);
    setCurrentWeekStart(newWeekStart);
    setDateRange([newWeekStart, endOfWeek(newWeekStart, { weekStartsOn: 1 })]);
  };

  const handleSubmitTimeEntry = async () => {
    try {
      if (!user || !user.emailAddresses || !user.emailAddresses[0]) {
        setError('User data not available');
        return;
      }

      if (!selectedJiraTicket?.key || !selectedJiraTicket?.summary) {
        setError('Please select a valid ticket');
        return;
      }

      if (!newEntry.hours && !newEntry.minutes) {
        setError('Please enter time');
        return;
      }

      if (!selectedJiraProject || !selectedJiraProject.key || !selectedJiraProject.name) {
        setError('Please select a valid project');
        return;
      }

      const totalMinutes = (parseInt(newEntry.hours) || 0) * 60 + (parseInt(newEntry.minutes) || 0);
      const duration = totalMinutes * 60 * 1000; // Convert to milliseconds

      const task = `${selectedJiraTicket.key} - ${selectedJiraTicket.summary}`;

      // Set the time to noon on the selected date to avoid timezone issues
      const entryDate = new Date(selectedDate);
      entryDate.setHours(12, 0, 0, 0);

      const timeEntry = {
        user_id: user.id,
        user_email: user.emailAddresses[0].emailAddress,
        user_name: `${user.firstName || ''} ${user.lastName || ''}`.trim(),
        date: format(entryDate, "yyyy-MM-dd'T'12:00:00.000XXX"),
        duration: duration,
        task: task,
        task_description: newEntry.description || '',
        jira_project_key: selectedJiraProject.key,
        jira_project_name: selectedJiraProject.name,
        jira_ticket_key: selectedJiraTicket.key,
        jira_ticket_summary: selectedJiraTicket.summary,
        created_at: new Date().toISOString()
      };

      const result = await supabase
        .from('time_entries')
        .insert([timeEntry]);

      if (result.error) throw result.error;

      // Reset form
      setNewEntry({
        hours: '',
        minutes: '',
        description: ''
      });
      setSelectedJiraProject(null);
      setSelectedJiraTicket(null);
      setJiraTickets([]);
      setShowForm(false);

      // Refresh entries for the current week
      const weekStart = startOfWeek(currentWeekStart, { weekStartsOn: 1 });
      const weekEnd = endOfWeek(weekStart, { weekStartsOn: 1 });

      const { data: updatedEntries, error: fetchError } = await supabase
        .from('time_entries')
        .select('*')
        .eq('user_email', user.emailAddresses[0].emailAddress)
        .gte('date', format(weekStart, 'yyyy-MM-dd'))
        .lte('date', format(weekEnd, 'yyyy-MM-dd'))
        .order('date', { ascending: false });

      if (fetchError) throw fetchError;
      setEntries(updatedEntries || []);

    } catch (error) {
      console.error('Error adding time entry:', error);
      setError('Failed to add time entry');
    }
  };

  // Process data for projects
  const processedData = useMemo(() => {
    const projectMap = new Map();
    
    entries.forEach(entry => {
      const entryDate = new Date(entry.date);
      
      const isInRange = !dateRange[0] || !dateRange[1] || (
        entryDate >= startOfDay(dateRange[0]) && 
        entryDate <= endOfDay(dateRange[1])
      );

      if (isInRange) {
        const projectKey = entry.jira_project_key;
        if (!projectMap.has(projectKey)) {
          projectMap.set(projectKey, {
            key: projectKey,
            name: entry.jira_project_name,
            entries: [],
            totalHours: 0,
            totalRevenue: 0
          });
        }
        
        const project = projectMap.get(projectKey);
        project.entries.push(entry);
        const hours = entry.duration / (1000 * 60 * 60);
        project.totalHours += hours;
        project.totalRevenue += hours * (projectRates[projectKey] || 0);
      }
    });

    return {
      projects: Array.from(projectMap.values())
        .filter(project => project.totalHours > 0)
        .sort((a, b) => b.totalHours - a.totalHours)
    };
  }, [entries, dateRange, projectRates]);

  const dateRangeValue = useMemo(() => {
    if (!dateRange[0] || !dateRange[1]) return undefined;
    return {
      from: dateRange[0],
      to: dateRange[1]
    };
  }, [dateRange]);

  const handleDateRangeChange = (range) => {
    if (!range) {
      setDateRange([null, null]);
      return;
    }

    const { from, to } = range;
    if (from) {
      if (!to) {
        const weekStart = startOfWeek(from, { weekStartsOn: 1 });
        const weekEnd = endOfWeek(weekStart, { weekStartsOn: 1 });
        setDateRange([weekStart, weekEnd]);
      } else {
        setDateRange([from, to]);
      }
    } else {
      setDateRange([null, null]);
    }
  };

  // Fetch project users and rates
  useEffect(() => {
    const fetchProjectData = async () => {
      try {
        // Fetch projects with rates
        const { data: projectsData, error: projectsError } = await supabase
          .from('jira_projects')
          .select('*')
          .order('name');

        if (projectsError) throw projectsError;
        setJiraProjects(projectsData);

        // Set project rates
        const rates = {};
        projectsData.forEach(project => {
          if (project.hourly_rate) {
            rates[project.key] = project.hourly_rate;
          }
        });
        setProjectRates(rates);

        // Check if user is admin
        if (user?.emailAddresses?.[0]?.emailAddress) {
          const { data: userData, error: userError } = await supabase
            .from('app_users')
            .select('is_admin')
            .eq('email', user.emailAddresses[0].emailAddress)
            .single();

          if (!userError && userData) {
            setIsAdmin(userData.is_admin);
          }
        }
      } catch (error) {
        console.error('Error fetching project data:', error);
        setError('Failed to load project data');
      }
    };

    fetchProjectData();
  }, [user?.emailAddresses]);

  // Add function to fetch Jira projects
  const fetchJiraProjects = async () => {
    try {
      const { data, error } = await supabase
        .from('jira_projects')
        .select('*')
        .order('name');

      if (error) throw error;
      setJiraProjects(data);
    } catch (error) {
      console.error('Error fetching Jira projects:', error);
      setError('Failed to load projects');
    }
  };

  // Add function to fetch Jira tickets
  const fetchJiraTickets = async (projectKey) => {
    if (!projectKey) return;
    
    setLoadingTickets(true);
    try {
      const { data, error } = await supabase
        .from('jira_tickets')
        .select('*')
        .eq('project_key', projectKey)
        .order('updated_at', { ascending: false });

      if (error) throw error;
      setJiraTickets(data);
    } catch (error) {
      console.error('Error fetching Jira tickets:', error);
      setError('Failed to load tickets');
    } finally {
      setLoadingTickets(false);
    }
  };

  // Add this useEffect after the other useEffects
  useEffect(() => {
    if (selectedView === 'logTime') {
      fetchJiraProjects();
    }
  }, [selectedView]);

  // Add this useEffect to handle clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.avatar-dropdown')) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Modify the avatar click handler
  const handleAvatarClick = (e) => {
    e.stopPropagation();
    setIsDropdownOpen(!isDropdownOpen);
  };

  // Add handleSignOut function
  const handleSignOut = async () => {
    try {
      await signOut();
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const formatDuration = (duration) => {
    const hours = Math.floor(duration / (1000 * 60 * 60));
    const minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));
    return `${hours}h ${minutes}m`;
  };

  // Add this to your existing useEffect that fetches data
  useEffect(() => {
    const fetchUserRates = async () => {
      try {
        const { data, error } = await supabase
          .from('app_users')
          .select('email, hourly_rate');

        if (error) throw error;

        const rates = {};
        data.forEach(user => {
          if (user.hourly_rate) {
            rates[user.email] = user.hourly_rate;
          }
        });
        setUserRates(rates);
      } catch (error) {
        console.error('Error fetching user rates:', error);
      }
    };

    fetchUserRates();
  }, []);

  // Add effect to ensure non-admin users stay on logTime view
  useEffect(() => {
    if (!isAdmin && selectedView !== 'logTime') {
      setSelectedView('logTime');
    }
  }, [isAdmin, selectedView]);

  // Load saved project selection when admin logs in
  useEffect(() => {
    const loadSavedProjectSelection = async () => {
      if (!isAdmin || !user?.emailAddresses?.[0]?.emailAddress) return;

      try {
        const { data, error } = await supabase
          .from('admin_preferences')
          .select('selected_projects')
          .eq('user_email', user.emailAddresses[0].emailAddress)
          .single();

        if (error && error.code !== 'PGRST116') {
          console.error('Error loading project selection:', error);
          return;
        }

        if (data?.selected_projects) {
          setSelectedProjects(data.selected_projects);
        }
      } catch (error) {
        console.error('Error loading project selection:', error);
      }
    };

    loadSavedProjectSelection();
  }, [isAdmin, user?.emailAddresses]);

  // Save project selection when it changes
  const handleProjectSelection = async (newSelection) => {
    setSelectedProjects(newSelection);

    if (!isAdmin || !user?.emailAddresses?.[0]?.emailAddress) return;

    try {
      const { error } = await supabase
        .from('admin_preferences')
        .upsert({
          user_email: user.emailAddresses[0].emailAddress,
          selected_projects: newSelection,
          updated_at: new Date().toISOString()
        });

      if (error) {
        console.error('Error saving project selection:', error);
      }
    } catch (error) {
      console.error('Error saving project selection:', error);
    }
  };

  // Add this effect to load weekly goals
  useEffect(() => {
    const loadWeeklyGoals = async () => {
      try {
        const { data, error } = await supabase
          .from('jira_projects')
          .select('key, weekly_goal');

        if (error) throw error;

        const goals = {};
        data.forEach(project => {
          if (project.weekly_goal) {
            goals[project.key] = project.weekly_goal;
          }
        });
        setProjectWeeklyGoals(goals);
      } catch (error) {
        console.error('Error loading weekly goals:', error);
      }
    };

    loadWeeklyGoals();
  }, []);

  // Add handler for weekly goal updates
  const handleWeeklyGoalUpdate = async (projectKey, newGoal) => {
    try {
      const { error } = await supabase
        .from('jira_projects')
        .update({ weekly_goal: newGoal })
        .eq('key', projectKey);

      if (error) throw error;

      setProjectWeeklyGoals(prev => ({
        ...prev,
        [projectKey]: newGoal
      }));
    } catch (error) {
      console.error('Error updating weekly goal:', error);
    }
  };

  return (
    <div className="h-screen overflow-hidden">
      {/* Left Drawer */}
      <div className="fixed inset-y-0 left-0 w-64 border-r bg-card">
        <div className="flex h-14 items-center justify-between border-b px-4">
          <h2 className="text-lg font-semibold">Ontora</h2>
          <div className="avatar-dropdown" style={{ position: 'relative' }}>
            <Avatar className="h-8 w-8 cursor-pointer" onClick={handleAvatarClick}>
              <AvatarFallback className={`bg-gradient-to-br ${getGradient(user?.emailAddresses?.[0]?.emailAddress)} text-white`}>
                {user?.firstName?.[0] || user?.emailAddresses?.[0]?.emailAddress?.[0] || '?'}
              </AvatarFallback>
            </Avatar>
            {isDropdownOpen && (
              <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-10">
                <button
                  onClick={handleSignOut}
                  className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                >
                  Sign Out
                </button>
              </div>
            )}
          </div>
        </div>
        <nav className="space-y-1 p-2">
          <button
            className={`w-full flex items-center gap-3 rounded-lg px-3 py-2 text-sm transition-colors ${
              selectedView === 'logTime' ? 'bg-primary/10 text-primary' : 'hover:bg-primary/5'
            }`}
            onClick={() => setSelectedView('logTime')}
          >
            <Timer className="h-4 w-4" />
            Log Time
          </button>
          {isAdmin && (
            <>
              <button
                className={`w-full flex items-center gap-3 rounded-lg px-3 py-2 text-sm transition-colors ${
                  selectedView === 'dashboard' ? 'bg-primary/10 text-primary' : 'hover:bg-primary/5'
                }`}
                onClick={() => setSelectedView('dashboard')}
              >
                <LayoutDashboard className="h-4 w-4" />
                Dashboard
              </button>
              <button
                className={`w-full flex items-center gap-3 rounded-lg px-3 py-2 text-sm transition-colors ${
                  selectedView === 'projects' ? 'bg-primary/10 text-primary' : 'hover:bg-primary/5'
                }`}
                onClick={() => setSelectedView('projects')}
              >
                <FolderKanban className="h-4 w-4" />
                Projects
              </button>
              <button
                className={`w-full flex items-center gap-3 rounded-lg px-3 py-2 text-sm transition-colors ${
                  selectedView === 'developers' ? 'bg-primary/10 text-primary' : 'hover:bg-primary/5'
                }`}
                onClick={() => setSelectedView('developers')}
              >
                <Users className="h-4 w-4" />
                Developers
              </button>
              {isAdmin && (
                <button
                  className={`w-full flex items-center gap-3 rounded-lg px-3 py-2 text-sm transition-colors ${
                    selectedView === 'activity' ? 'bg-primary/10 text-primary' : 'hover:bg-primary/5'
                  }`}
                  onClick={() => setSelectedView('activity')}
                >
                  <Activity className="h-4 w-4" />
                  Activity
                </button>
              )}
              <button
                className={`w-full flex items-center gap-3 rounded-lg px-3 py-2 text-sm transition-colors ${
                  selectedView === 'ops' ? 'bg-primary/10 text-primary' : 'hover:bg-primary/5'
                }`}
                onClick={() => setSelectedView('ops')}
              >
                <Settings className="h-4 w-4" />
                Settings
              </button>
            </>
          )}
        </nav>
      </div>

      {/* Top Bar */}
      <div className="fixed top-0 left-64 right-0 h-14 border-b bg-background flex items-center justify-end px-4 z-10">
      </div>

      {/* Main Content */}
      <div className="pl-64 pt-14 h-screen overflow-auto">
        <main className="container py-6">
          {/* Log Time View */}
          {selectedView === 'logTime' && (
            <div className="space-y-6">
              <div className="flex items-center justify-between">
                <div>
                  <h2 className="text-2xl font-bold tracking-tight">Log Time</h2>
                  <p className="text-muted-foreground">
                    Track your time on projects and tasks
                  </p>
                </div>
                <div className="flex items-center space-x-2">
                  <Button variant="outline" size="icon" onClick={handlePrevWeek}>
                    <ChevronLeft className="h-4 w-4" />
                  </Button>
                  <h2 className="text-lg font-semibold">
                    {format(currentWeekStart, 'MMM d')} - {format(endOfWeek(currentWeekStart, { weekStartsOn: 1 }), 'MMM d, yyyy')}
                  </h2>
                  <Button variant="outline" size="icon" onClick={handleNextWeek}>
                    <ChevronRight className="h-4 w-4" />
                  </Button>
                </div>
              </div>

              <div className="grid grid-cols-7 gap-4">
                {Array.from({ length: 7 }, (_, i) => addDays(currentWeekStart, i)).map((date) => {
                  const dayEntries = getEntriesForDate(date);
                  const totalHours = getTotalHoursForDate(date);
                  
                  return (
                    <Card
                      key={date.toISOString()}
                      className={cn(
                        "p-4",
                        isToday(date) && "border-primary",
                        isSameDay(date, selectedDate) && "bg-muted"
                      )}
                    >
                      <div className="text-center mb-2">
                        <div className="font-medium">{format(date, 'EEE')}</div>
                        <div className="text-sm text-muted-foreground">{format(date, 'd')}</div>
                        {totalHours > 0 && (
                          <div className="text-xs text-muted-foreground mt-1">
                            Total: {totalHours.toFixed(1)}h
                          </div>
                        )}
                      </div>

                      <div className="space-y-2 mb-2 max-h-[200px] overflow-y-auto">
                        {dayEntries.map((entry) => (
                          <div
                            key={entry.id}
                            className="text-xs p-2 bg-muted rounded-md"
                          >
                            <div className="font-medium text-primary">
                              {entry.jira_project_name}
                            </div>
                            <div className="truncate">
                              {entry.task}
                            </div>
                            <div className="flex items-center gap-1 text-muted-foreground mt-1">
                              <Clock className="h-3 w-3" />
                              {formatDuration(entry.duration)}
                            </div>
                          </div>
                        ))}
                      </div>

                      <Button
                        variant="outline"
                        className="w-full"
                        onClick={() => {
                          setSelectedDate(date);
                          setShowForm(true);
                        }}
                      >
                        <Plus className="h-4 w-4" />
                      </Button>
                    </Card>
                  );
                })}
              </div>

              {showForm && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
                  <Card className="w-full max-w-2xl">
                    <CardContent className="pt-6">
                      <form onSubmit={async (e) => {
                        e.preventDefault();
                        if (!selectedJiraTicket?.key || !selectedJiraTicket?.summary) {
                          setError('Please select a valid ticket');
                          return;
                        }
                        await handleSubmitTimeEntry();
                      }} className="space-y-4">
                        <div className="grid gap-4 md:grid-cols-2">
                          <div className="space-y-2">
                            <label className="text-sm font-medium">Project</label>
                            <select
                              className="w-full rounded-md border border-input bg-background px-3 py-2"
                              value={selectedJiraProject?.key || ''}
                              onChange={(e) => {
                                const project = jiraProjects.find(p => p.key === e.target.value);
                                setSelectedJiraProject(project);
                                if (project) {
                                  fetchJiraTickets(project.key);
                                }
                                setSelectedJiraTicket(null);
                              }}
                            >
                              <option value="">Select a project</option>
                              {jiraProjects.map((project) => (
                                <option key={project.key} value={project.key}>
                                  {project.name}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div className="space-y-2">
                            <label className="text-sm font-medium">Ticket</label>
                            <select
                              className="w-full rounded-md border border-input bg-background px-3 py-2"
                              value={selectedJiraTicket?.key || ''}
                              onChange={(e) => {
                                const ticket = jiraTickets.find(t => t.key === e.target.value);
                                setSelectedJiraTicket(ticket);
                              }}
                              disabled={!selectedJiraProject || loadingTickets}
                            >
                              <option value="">Select a ticket</option>
                              {jiraTickets.map((ticket) => (
                                <option key={ticket.key} value={ticket.key}>
                                  {`${ticket.key} - ${ticket.summary}`}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div className="space-y-2">
                            <label className="text-sm font-medium">Hours</label>
                            <input
                              type="number"
                              className="w-full rounded-md border border-input bg-background px-3 py-2"
                              value={newEntry.hours}
                              onChange={(e) => setNewEntry({ ...newEntry, hours: e.target.value })}
                              min="0"
                              max="24"
                            />
                          </div>

                          <div className="space-y-2">
                            <label className="text-sm font-medium">Minutes</label>
                            <input
                              type="number"
                              className="w-full rounded-md border border-input bg-background px-3 py-2"
                              value={newEntry.minutes}
                              onChange={(e) => setNewEntry({ ...newEntry, minutes: e.target.value })}
                              min="0"
                              max="59"
                            />
                          </div>

                          <div className="space-y-2 md:col-span-2">
                            <label className="text-sm font-medium">Description</label>
                            <textarea
                              className="w-full rounded-md border border-input bg-background px-3 py-2"
                              value={newEntry.description}
                              onChange={(e) => setNewEntry({ ...newEntry, description: e.target.value })}
                              rows={4}
                              placeholder="Describe your work..."
                            />
                          </div>
                        </div>

                        <div className="flex justify-end space-x-2">
                          <Button
                            type="button"
                            variant="outline"
                            onClick={() => setShowForm(false)}
                          >
                            Cancel
                          </Button>
                          <Button
                            type="submit"
                            disabled={!selectedJiraTicket || (!newEntry.hours && !newEntry.minutes)}
                          >
                            Submit Time Entry
                          </Button>
                        </div>
                      </form>
                    </CardContent>
                  </Card>
                </div>
              )}
            </div>
          )}

          {/* Admin-only views */}
          {isAdmin && (
            <>
              {/* Dashboard View */}
              {selectedView === 'dashboard' && (
                <div className="space-y-6">
                  <div className="flex items-center justify-between">
                    <div>
                      <h2 className="text-2xl font-bold tracking-tight">Dashboard</h2>
                      <p className="text-muted-foreground">
                        Overview of your time tracking
                      </p>
                    </div>
                    <div className="flex items-center gap-4">
                      <DateRangePicker
                        value={dateRangeValue}
                        onChange={handleDateRangeChange}
                      />
                    </div>
                  </div>

                  <Timesheet 
                    entries={entries}
                    dateRange={dateRange}
                    onAddEntry={() => setShowForm(true)}
                    projectRates={projectRates}
                    userRates={userRates}
                  />
                </div>
              )}

              {/* Projects View */}
              {selectedView === 'projects' && (
                <div className="space-y-6 pb-24">
                  <div className="flex items-center justify-between">
                    <div>
                      <h2 className="text-2xl font-bold tracking-tight">Projects</h2>
                      <p className="text-muted-foreground">
                        Track time and monitor project progress
                      </p>
                    </div>
                    <div className="flex items-center gap-4">
                      <Popover open={openProjectSelect} onOpenChange={setOpenProjectSelect}>
                        <PopoverTrigger asChild>
                          <Button
                            variant="outline"
                            role="combobox"
                            aria-expanded={openProjectSelect}
                            className="min-w-[200px] justify-between"
                          >
                            {selectedProjects.length === 0
                              ? "All Projects"
                              : `${selectedProjects.length} selected`}
                            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                          </Button>
                        </PopoverTrigger>
                        <PopoverContent className="w-[300px] p-0" align="start">
                          <Command>
                            <CommandInput placeholder="Search projects..." />
                            <CommandEmpty>No projects found.</CommandEmpty>
                            <CommandGroup className="max-h-[300px] overflow-auto">
                              <div className="p-2">
                                <Button
                                  variant="outline"
                                  size="sm"
                                  className="w-full mb-2"
                                  onClick={() => setSelectedProjects([])}
                                >
                                  Clear Selection
                                </Button>
                                {jiraProjects.map((project) => (
                                  <div
                                    key={project.key}
                                    className="flex items-center space-x-2 p-2 hover:bg-accent rounded-sm cursor-pointer"
                                    onClick={() => {
                                      const newSelection = (() => {
                                        const selected = new Set(selectedProjects);
                                        if (selected.has(project.key)) {
                                          selected.delete(project.key);
                                        } else {
                                          selected.add(project.key);
                                        }
                                        return Array.from(selected);
                                      })();
                                      handleProjectSelection(newSelection);
                                    }}
                                  >
                                    <div className={cn(
                                      "h-4 w-4 border rounded-sm flex items-center justify-center",
                                      selectedProjects.includes(project.key) ? "bg-primary border-primary" : "border-input"
                                    )}>
                                      {selectedProjects.includes(project.key) && (
                                        <Check className="h-3 w-3 text-primary-foreground" />
                                      )}
                                    </div>
                                    <span className="text-sm">{project.name}</span>
                                  </div>
                                ))}
                              </div>
                            </CommandGroup>
                          </Command>
                        </PopoverContent>
                      </Popover>
                      <DateRangePicker
                        value={dateRangeValue}
                        onChange={handleDateRangeChange}
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
                    {jiraProjects
                      .filter(project => !selectedProjects.length || selectedProjects.includes(project.key))
                      .map(project => {
                        // Ensure we have valid date range
                        if (!dateRange[0] || !dateRange[1]) {
                          return (
                            <ProjectChart
                              key={project.key}
                              project={{
                                ...project,
                                weekly_goal: projectWeeklyGoals[project.key],
                                weeklyHours: 0,
                                weeklyRevenue: 0
                              }}
                              entries={[]}
                              dateRange={dateRange}
                              projectRate={projectRates[project.key]}
                              projectUsers={projectUsers[project.key]}
                              isAdmin={isAdmin}
                              onRateChange={async (projectKey, newRate) => {
                                // Update local state immediately
                                setProjectRates(prev => ({
                                  ...prev,
                                  [projectKey]: newRate
                                }));
                                
                                // Then update the database
                                try {
                                  const { error } = await supabase
                                    .from('jira_projects')
                                    .update({ hourly_rate: newRate })
                                    .eq('key', projectKey);
                                  
                                  if (error) throw error;
                                } catch (error) {
                                  console.error('Error updating project rate:', error);
                                  // Revert on error
                                  setProjectRates(prev => ({
                                    ...prev,
                                    [projectKey]: projectRates[projectKey]
                                  }));
                                }
                              }}
                              onWeeklyGoalChange={async (projectKey, newGoal) => {
                                // Update local state immediately
                                setProjectWeeklyGoals(prev => ({
                                  ...prev,
                                  [projectKey]: newGoal
                                }));
                                
                                // Then update the database
                                try {
                                  const { error } = await supabase
                                    .from('jira_projects')
                                    .update({ weekly_goal: newGoal })
                                    .eq('key', projectKey);
                                  
                                  if (error) throw error;
                                } catch (error) {
                                  console.error('Error updating weekly goal:', error);
                                  // Revert on error
                                  setProjectWeeklyGoals(prev => ({
                                    ...prev,
                                    [projectKey]: projectWeeklyGoals[projectKey]
                                  }));
                                }
                              }}
                            />
                          );
                        }

                        // Filter entries for this project within the date range
                        const projectEntries = entries.filter(e => {
                          const entryDate = new Date(e.date);
                          return e.jira_project_key === project.key && 
                                 entryDate >= startOfDay(dateRange[0]) && 
                                 entryDate <= endOfDay(dateRange[1]);
                        });

                        // Calculate total hours and revenue
                        const hours = projectEntries.reduce((sum, entry) => 
                          sum + (entry.duration / (1000 * 60 * 60)), 0);
                        const revenue = hours * (projectRates[project.key] || 0);
                        
                        return (
                          <ProjectChart
                            key={project.key}
                            project={{
                              ...project,
                              weekly_goal: projectWeeklyGoals[project.key],
                              weeklyHours: hours,
                              weeklyRevenue: revenue
                            }}
                            entries={projectEntries}
                            dateRange={dateRange}
                            projectRate={projectRates[project.key]}
                            projectUsers={projectUsers[project.key]}
                            isAdmin={isAdmin}
                            onRateChange={async (projectKey, newRate) => {
                              // Update local state immediately
                              setProjectRates(prev => ({
                                ...prev,
                                [projectKey]: newRate
                              }));
                              
                              // Then update the database
                              try {
                                const { error } = await supabase
                                  .from('jira_projects')
                                  .update({ hourly_rate: newRate })
                                  .eq('key', projectKey);
                                
                                if (error) throw error;
                              } catch (error) {
                                console.error('Error updating project rate:', error);
                                // Revert on error
                                setProjectRates(prev => ({
                                  ...prev,
                                  [projectKey]: projectRates[projectKey]
                                }));
                              }
                            }}
                            onWeeklyGoalChange={async (projectKey, newGoal) => {
                              // Update local state immediately
                              setProjectWeeklyGoals(prev => ({
                                ...prev,
                                [projectKey]: newGoal
                              }));
                              
                              // Then update the database
                              try {
                                const { error } = await supabase
                                  .from('jira_projects')
                                  .update({ weekly_goal: newGoal })
                                  .eq('key', projectKey);
                                
                                if (error) throw error;
                              } catch (error) {
                                console.error('Error updating weekly goal:', error);
                                // Revert on error
                                setProjectWeeklyGoals(prev => ({
                                  ...prev,
                                  [projectKey]: projectWeeklyGoals[projectKey]
                                }));
                              }
                            }}
                          />
                        );
                      })}
                  </div>

                  <ProjectSummaryTable
                    projects={jiraProjects.filter(project => !selectedProjects.length || selectedProjects.includes(project.key))}
                    entries={entries}
                    projectRates={projectRates}
                    dateRange={dateRange}
                  />
                </div>
              )}

              {/* Developers View (formerly Reports) */}
              {selectedView === 'developers' && (
                <UserReports
                  entries={entries}
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  user={user}
                  signOut={signOut}
                  isAdmin={isAdmin}
                  loading={loading}
                  error={error}
                  formatDuration={(duration) => {
                    const hours = Math.floor(duration / (1000 * 60 * 60));
                    const minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));
                    return `${hours}h ${minutes}m`;
                  }}
                  deleteEntry={async (entryId) => {
                    if (!window.confirm('Are you sure you want to delete this entry?')) return;
                    try {
                      const { error } = await supabase
                        .from('time_entries')
                        .delete()
                        .eq('id', entryId);

                      if (error) throw error;

                      // Update local state
                      setEntries(prev => prev.filter(entry => entry.id !== entryId));
                    } catch (error) {
                      console.error('Error deleting entry:', error);
                      setError('Failed to delete entry');
                    }
                  }}
                />
              )}

              {/* Activity View */}
              {isAdmin && selectedView === 'activity' && (
                <div className="space-y-6">
                  <div className="flex items-center justify-between">
                    <div>
                      <h2 className="text-2xl font-bold tracking-tight">Activity</h2>
                      <p className="text-muted-foreground">
                        Recent time tracking activity
                      </p>
                    </div>
                  </div>

                  <div className="relative">
                    <div className="absolute left-8 top-3 bottom-3 w-px bg-border" />
                    <div className="space-y-6">
                      {entries
                        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                        .map((entry) => (
                          <div key={entry.id} className="relative pl-12">
                            <div className="absolute left-[29px] top-3 w-3 h-3 rounded-full border-2 border-primary bg-background" />
                            <Card>
                              <CardContent className="pt-6">
                                <div className="flex items-start justify-between">
                                  <div className="space-y-1">
                                    <div className="flex items-center gap-2">
                                      <Avatar className="h-6 w-6">
                                        <AvatarFallback className={`bg-gradient-to-br ${getGradient(entry.user_email)} text-[10px] text-white`}>
                                          {entry.user_name?.[0] || entry.user_email?.[0] || '?'}
                                        </AvatarFallback>
                                      </Avatar>
                                      <span className="font-medium">{entry.user_name}</span>
                                      <span className="text-muted-foreground">logged time for</span>
                                      <span className="font-medium">{entry.jira_project_name}</span>
                                    </div>
                                    <div className="flex items-center gap-2 text-sm text-muted-foreground">
                                      <span>Logged {formatDistanceToNow(new Date(entry.created_at), { addSuffix: true })}</span>
                                      <span>•</span>
                                      <span>For {format(new Date(entry.date), 'MMMM d, yyyy')}</span>
                                    </div>
                                  </div>
                                  <Badge variant="outline" className="flex items-center gap-1">
                                    <Clock className="h-3 w-3" />
                                    {(entry.duration / (1000 * 60 * 60)).toFixed(1)}h
                                  </Badge>
                                </div>
                                <div className="mt-4 space-y-2">
                                  <div className="font-medium">{entry.task}</div>
                                  {entry.task_description && (
                                    <div className="text-sm text-muted-foreground">
                                      {entry.task_description}
                                    </div>
                                  )}
                                </div>
                              </CardContent>
                            </Card>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              )}

              {/* Settings View */}
              {selectedView === 'ops' && (
                <div className="space-y-6">
                  <div className="flex items-center justify-between">
                    <div>
                      <h2 className="text-2xl font-bold tracking-tight">Settings</h2>
                      <p className="text-muted-foreground">
                        Configure application settings
                      </p>
                    </div>
                  </div>

                  <Tabs defaultValue="team" className="space-y-4">
                    <TabsList>
                      <TabsTrigger value="team">Team</TabsTrigger>
                      <TabsTrigger value="general">General</TabsTrigger>
                      <TabsTrigger value="integrations">Integrations</TabsTrigger>
                    </TabsList>

                    <TabsContent value="team">
                      <Members 
                        isAdmin={isAdmin} 
                        user={user}
                      />
                    </TabsContent>

                    <TabsContent value="general">
                      <Card>
                        <CardContent className="pt-6">
                          <p className="text-sm text-muted-foreground">General settings coming soon.</p>
                        </CardContent>
                      </Card>
                    </TabsContent>

                    <TabsContent value="integrations">
                      <Card>
                        <CardContent className="pt-6">
                          <p className="text-sm text-muted-foreground">Integration settings coming soon.</p>
                        </CardContent>
                      </Card>
                    </TabsContent>
                  </Tabs>
                </div>
              )}
            </>
          )}
        </main>
      </div>
    </div>
  );
};

// Move export statement to top level
export default TimeTracker;