import React, { memo, useState, useEffect } from 'react';
import { Card, CardHeader, CardTitle, CardDescription, CardContent } from './ui/card';
import { DollarSign, Check, X, Clock } from 'lucide-react';
import { createClient } from '@supabase/supabase-js';
import { Progress } from './ui/progress';

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
);

const ProjectChart = memo(({ 
  project, 
  entries,
  dateRange,
  projectRate, 
  isAdmin,
  onRateChange,
  onWeeklyGoalChange
}) => {
  const [isEditingRate, setIsEditingRate] = useState(false);
  const [isEditingGoal, setIsEditingGoal] = useState(false);
  const [editedRate, setEditedRate] = useState(projectRate?.toString() || '');
  const [editedGoal, setEditedGoal] = useState(project.weekly_goal?.toString() || '40');
  const [saving, setSaving] = useState(false);

  // Use project's weekly goal or default to 40 hours
  const weeklyGoal = project.weekly_goal || 40;
  const weeklyHours = project.weeklyHours || 0;
  const progress = Math.min(Math.max((weeklyHours / weeklyGoal) * 100, 0), 100);

  // Update editedGoal when project.weekly_goal changes
  useEffect(() => {
    setEditedGoal(project.weekly_goal?.toString() || '40');
  }, [project.weekly_goal]);

  const handleSaveRate = async () => {
    const newRate = parseFloat(editedRate);
    if (isNaN(newRate)) return;

    setSaving(true);
    try {
      const { error } = await supabase
        .from('jira_projects')
        .update({ hourly_rate: newRate })
        .eq('key', project.key);

      if (error) throw error;

      onRateChange(project.key, newRate);
      setIsEditingRate(false);
    } catch (error) {
      console.error('Error updating project rate:', error);
    } finally {
      setSaving(false);
    }
  };

  const handleSaveGoal = async () => {
    const newGoal = parseFloat(editedGoal);
    if (isNaN(newGoal)) return;

    setSaving(true);
    try {
      await onWeeklyGoalChange(project.key, newGoal);
      setIsEditingGoal(false);
    } catch (error) {
      console.error('Error updating weekly goal:', error);
    } finally {
      setSaving(false);
    }
  };

  return (
    <Card>
      <CardHeader>
        <div className="flex items-center justify-between">
          <div>
            <div className="flex items-center gap-4">
              <CardTitle>{project.name}</CardTitle>
              {isAdmin && (
                <div className="flex items-center gap-2">
                  {isEditingRate ? (
                    <>
                      <div className="relative">
                        <DollarSign className="absolute left-2 top-1/2 -translate-y-1/2 h-4 w-4 text-muted-foreground" />
                        <input
                          type="number"
                          value={editedRate}
                          onChange={(e) => setEditedRate(e.target.value)}
                          className="w-24 pl-8 h-9 rounded-md border border-input bg-background px-3 py-1 text-sm shadow-sm transition-colors placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring"
                          placeholder="0.00"
                          step="0.01"
                          min="0"
                          disabled={saving}
                        />
                      </div>
                      <button
                        onClick={handleSaveRate}
                        disabled={saving}
                        className="inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring h-9 w-9 border border-input bg-background hover:bg-accent hover:text-accent-foreground"
                      >
                        <Check className="h-4 w-4" />
                      </button>
                      <button
                        onClick={() => {
                          setIsEditingRate(false);
                          setEditedRate(projectRate?.toString() || '');
                        }}
                        className="inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring h-9 w-9 border border-input bg-background hover:bg-accent hover:text-accent-foreground"
                      >
                        <X className="h-4 w-4" />
                      </button>
                    </>
                  ) : (
                    <button
                      onClick={() => setIsEditingRate(true)}
                      className="inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring px-3 py-1 border border-input bg-background hover:bg-accent hover:text-accent-foreground"
                    >
                      {projectRate ? `$${projectRate}/hr` : 'Set Rate'}
                    </button>
                  )}

                  {isEditingGoal ? (
                    <>
                      <div className="relative">
                        <Clock className="absolute left-2 top-1/2 -translate-y-1/2 h-4 w-4 text-muted-foreground" />
                        <input
                          type="number"
                          value={editedGoal}
                          onChange={(e) => setEditedGoal(e.target.value)}
                          className="w-24 pl-8 h-9 rounded-md border border-input bg-background px-3 py-1 text-sm shadow-sm transition-colors placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring"
                          placeholder="40"
                          step="1"
                          min="0"
                          disabled={saving}
                        />
                      </div>
                      <button
                        onClick={handleSaveGoal}
                        disabled={saving}
                        className="inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring h-9 w-9 border border-input bg-background hover:bg-accent hover:text-accent-foreground"
                      >
                        <Check className="h-4 w-4" />
                      </button>
                      <button
                        onClick={() => {
                          setIsEditingGoal(false);
                          setEditedGoal(project.weekly_goal?.toString() || '40');
                        }}
                        className="inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring h-9 w-9 border border-input bg-background hover:bg-accent hover:text-accent-foreground"
                      >
                        <X className="h-4 w-4" />
                      </button>
                    </>
                  ) : (
                    <button
                      onClick={() => setIsEditingGoal(true)}
                      className="inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring px-3 py-1 border border-input bg-background hover:bg-accent hover:text-accent-foreground"
                    >
                      {weeklyGoal}h
                    </button>
                  )}
                </div>
              )}
            </div>
            <CardDescription className="mt-1.5">
              <div className="flex items-center gap-4">
                <span>{project.weeklyHours.toFixed(1)} / {weeklyGoal} hours this week</span>
                {isAdmin && projectRate > 0 && (
                  <span className="text-emerald-500">
                    ${project.weeklyRevenue.toFixed(2)} billed
                  </span>
                )}
              </div>
            </CardDescription>
          </div>
        </div>
      </CardHeader>
      <CardContent>
        <div className="space-y-2">
          <Progress value={progress} className="h-2" />
          <div className="text-sm text-muted-foreground">
            {progress.toFixed(1)}% of weekly goal
          </div>
        </div>
      </CardContent>
    </Card>
  );
});

ProjectChart.displayName = "ProjectChart";

export default ProjectChart; 